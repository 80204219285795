<template>
    <div v-if="project" id="project" class="main-content">
        <div class="project-wrapper tracking-wider text-xs" ref="wrapper">
            
            <div @click="router.push('/')" class="mb-10 uppercase cursor-pointer"> 
                <span class="border border-black p-2 hover:bg-black hover:text-white">
                    [ X ] fermer
                </span>
            </div>

            <div class='embed-container'>
                <iframe :src="'https://www.youtube.com/embed/' + project.videoId + '?si=14CxaxRESdekcVHN&amp;controls=0&modestbranding=1&rel=0&&showinfo=0'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div class="mt-6 uppercase">
                <div class="mb-1">
                    {{ project.client }} 
                </div>
                <div class="font-bold">
                    [ {{ project.name }} ]
                </div>
            </div>

            <div class="mt-10 font-['Roboto_Mono'] uppercase">
                <div class="mb-8">[ vous aimerez peut être ]</div>
                <List :category="project.category.slug.current"></List>
            </div>

        </div>
    </div>
</template>

<style scoped>
.project-wrapper {
    @apply dark:text-white dark:bg-[#121212] lg:p-20 p-5
    text-black bg-white lg:w-[75vw] lg:ml-[25vw] md:ml-[25vw]
    pt-32 pb-32 
}
.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 
.embed-container iframe, .embed-container object, .embed-container embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
}
</style>

<script setup>
import List from '../components/List.vue'
import {createClient} from '@sanity/client'
import { onMounted, onBeforeMount, onUpdated } from 'vue';
import { ref } from 'vue';
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { useRouter, useRoute } from 'vue-router';
import Lenis from '@studio-freight/lenis'

const route = useRoute()
const router = useRouter()
const wrapper = ref(null)
const project = ref(null)
 
const client = createClient({
  projectId: 'ruqolkxa',
  dataset: 'production',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
})


onMounted(async ()=>{
    await client.fetch(`*[_type == "project" && slug.current == "` + route.params.slug + `"]{
        name,
        slug,
        client,
        "preview": preview.asset->url,
        category->, 
        "image": image.asset->url,
        videoId
    }`).then((response)=>{
        project.value = response[0]
    })

        //smooth scroll
        const lenis = new Lenis() 
    lenis.on('scroll', ScrollTrigger.update) 
    gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
    }) 
    gsap.ticker.lagSmoothing(0)

})

onUpdated(()=>{ 
    if (document.querySelector('.ytp-show-cards-title')) {
        document.querySelector('.ytp-show-cards-title').style.display = 'none'
    }
    let tl = gsap.timeline()
    tl.fromTo(wrapper.value, {y: 200, opacity: 0, scaleY: 1.25}, {y: 0, scaleY: 1, opacity: 1, duration: 1.25, delay: .5, ease: 'expo.out'}) 
})
</script>