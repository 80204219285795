<template>
    <div id="open-menu" @click="openMenu" class="block lg:hidden md:hidden fixed uppercase right-5 top-5 bg-white z-40 p-2 text-xs 
    duration-200 border border-black hover:text-white hover:bg-black">[ menu ]</div>
    
    <div id="close-menu" ref="closeMenuBtn" @click="closeMenu" class="absolute uppercase right-5 top-5 bg-white z-[160] p-2 text-xs 
    duration-200 border border-black hover:text-white hover:bg-black font-['Roboto_Mono'] hidden">[X] fermer</div>

    <div ref="wrapper" class="fixed z-[150] bg-white w-full h-full hidden flex-col justify-center items-center 
    font-['Roboto_Mono'] uppercase lg:text-7xl text-3xl gap-4">

        <div @click="goToCategory('/')" ref="logo" class="flex flex-col justify-center items-center w-full cursor-pointer mb-16">
            <div class="w-60 lg:w-42 relative dark:fill-white">
            <svg x="0px" y="0px"
                viewBox="0 0 300 140" xml:space="preserve">
            <g>
                <path d="M28.7,106.3c1,1.8,2.4,3.3,4,4.4c1.6,1.1,3.5,2,5.6,2.5c2.1,0.5,4.3,0.8,6.6,0.8c1.6,0,3.4-0.2,5.2-0.6
                c1.8-0.4,3.4-1,4.9-1.8c1.5-0.8,2.7-1.9,3.7-3.3c1-1.4,1.5-3.1,1.5-5.2c0-3.5-2.3-6.1-7-7.9c-4.6-1.7-11.1-3.5-19.4-5.2
                c-3.4-0.8-6.7-1.7-9.9-2.7c-3.2-1-6.1-2.4-8.6-4.1c-2.5-1.7-4.5-3.8-6.1-6.4C7.8,74.5,7,71.3,7,67.6c0-5.5,1.1-9.9,3.2-13.4
                c2.1-3.5,4.9-6.2,8.4-8.3c3.5-2,7.4-3.4,11.8-4.3c4.4-0.8,8.8-1.2,13.4-1.2s9,0.4,13.3,1.3c4.3,0.9,8.2,2.3,11.5,4.4
                c3.4,2.1,6.2,4.8,8.4,8.3c2.2,3.4,3.6,7.8,4,13H59.1c-0.3-4.5-2-7.5-5.1-9.1C51,56.8,47.4,56,43.2,56c-1.3,0-2.7,0.1-4.3,0.2
                c-1.5,0.2-2.9,0.5-4.2,1.1c-1.3,0.5-2.3,1.3-3.2,2.4c-0.9,1-1.3,2.4-1.3,4.2c0,2.1,0.8,3.8,2.3,5.1c1.5,1.3,3.5,2.4,6,3.2
                c2.5,0.8,5.3,1.6,8.4,2.2c3.2,0.7,6.4,1.4,9.7,2.1c3.4,0.8,6.7,1.7,9.9,2.8c3.2,1.1,6.1,2.5,8.6,4.3c2.5,1.8,4.5,4,6.1,6.7
                c1.5,2.7,2.3,6,2.3,9.9c0,5.6-1.1,10.2-3.4,14c-2.2,3.8-5.2,6.8-8.8,9.1c-3.6,2.3-7.7,3.9-12.4,4.8c-4.6,0.9-9.4,1.4-14.2,1.4
                c-4.9,0-9.7-0.5-14.4-1.5c-4.7-1-8.9-2.6-12.5-4.9c-3.7-2.3-6.7-5.3-9-9.1c-2.3-3.8-3.6-8.5-3.8-14.2h22.1
                C27.2,102.4,27.7,104.5,28.7,106.3z"/>
                <path d="M115.7,107.1c3.5,3.4,8.5,5.1,15.1,5.1c4.7,0,8.7-1.2,12.1-3.5c3.4-2.3,5.5-4.8,6.2-7.4h20.5c-3.3,10.1-8.3,17.4-15.1,21.8
                c-6.8,4.4-15,6.5-24.6,6.5c-6.7,0-12.7-1.1-18-3.2c-5.3-2.1-9.9-5.2-13.6-9.1c-3.7-3.9-6.6-8.6-8.6-14.1c-2-5.5-3-11.5-3-18
                c0-6.3,1-12.2,3.1-17.7c2.1-5.5,5-10.2,8.8-14.2c3.8-4,8.4-7.1,13.7-9.4c5.3-2.3,11.2-3.4,17.6-3.4c7.2,0,13.5,1.4,18.8,4.2
                c5.3,2.8,9.7,6.5,13.2,11.2c3.4,4.7,5.9,10,7.4,16c1.5,6,2.1,12.3,1.6,18.8H110C110.3,98.2,112.2,103.7,115.7,107.1z M142,62.6
                c-2.8-3.1-7-4.6-12.7-4.6c-3.7,0-6.8,0.6-9.2,1.9c-2.5,1.3-4.4,2.8-5.9,4.7c-1.5,1.9-2.5,3.8-3.1,5.9c-0.6,2.1-1,3.9-1.1,5.6h37.8
                C146.7,70.1,144.8,65.6,142,62.6z"/>
                <path d="M202.5,10.5v116.9h-23.2V10.5H202.5z"/>
                <path d="M239.5,107.1c3.5,3.4,8.5,5.1,15.1,5.1c4.7,0,8.7-1.2,12.1-3.5c3.4-2.3,5.5-4.8,6.2-7.4h20.5c-3.3,10.1-8.3,17.4-15.1,21.8
                c-6.8,4.4-15,6.5-24.6,6.5c-6.7,0-12.7-1.1-18-3.2c-5.3-2.1-9.9-5.2-13.6-9.1c-3.7-3.9-6.6-8.6-8.6-14.1c-2-5.5-3-11.5-3-18
                c0-6.3,1-12.2,3.1-17.7c2.1-5.5,5-10.2,8.8-14.2c3.8-4,8.4-7.1,13.7-9.4c5.3-2.3,11.2-3.4,17.6-3.4c7.2,0,13.5,1.4,18.8,4.2
                c5.3,2.8,9.7,6.5,13.2,11.2c3.4,4.7,5.9,10,7.4,16c1.5,6,2.1,12.3,1.6,18.8h-61.1C234.1,98.2,236,103.7,239.5,107.1z M265.7,62.6
                c-2.8-3.1-7-4.6-12.7-4.6c-3.7,0-6.8,0.6-9.2,1.9c-2.5,1.3-4.4,2.8-5.9,4.7c-1.5,1.9-2.5,3.8-3.1,5.9c-0.6,2.1-1,3.9-1.1,5.6h37.8
                C270.5,70.1,268.5,65.6,265.7,62.6z"/>
            </g>
            </svg>
            </div> 
        </div>

        <div @click="goToCategory('/bio')" style="clip-path: inset(-40px 0);" v-html="Splitting.html({ content: 'bio', by: 'chars' })"></div>
        <div @click="goToCategory('/clips')" style="clip-path: inset(-40px 0);" v-html="Splitting.html({ content: 'clips', by: 'chars' })"></div>
        <div @click="goToCategory('/commercial')" style="clip-path: inset(-40px 0);" v-html="Splitting.html({ content: 'commercials', by: 'chars' })"></div>
        <div @click="goToCategory('/cinema')" style="clip-path: inset(-40px 0);" v-html="Splitting.html({ content: 'cinéma', by: 'chars' })"></div>
        <a href='https://www.linkedin.com/in/mame-s%C3%A9l%C3%A9mane-dieye-b99885a8/' target="_blank" rel="noopener noreferrer" class="text-base text-gray-500" style="clip-path: inset(-40px 0);" v-html="Splitting.html({ content: 'linkedin', by: 'chars' })"></a>
        <a href='https://www.instagram.com/seleman_dieye/' target="_blank" rel="noopener noreferrer" class="text-base text-gray-500"  style="clip-path: inset(-40px 0);" v-html="Splitting.html({ content: 'instagram', by: 'chars' })"></a>
        <a href="tel:+221774094559" class="text-base text-gray-500"  style="clip-path: inset(-40px 0);" v-html="Splitting.html({ content: 'téléphone', by: 'chars' })"></a>
        <a href="mailto:selevfx@gmail.com" class="text-base text-gray-500"  style="clip-path: inset(-40px 0);" v-html="Splitting.html({ content: 'email', by: 'chars' })"></a>
        <!-- <div ref="credits" class="text-xs font-['Roboto_Mono'] text-center mt-16">
             <div class="font-bold"><span class="font-light">design & développement</span> la petite tribu</div>
        </div> -->

    </div>

</template>

<script setup>
import Splitting from 'splitting'
import gsap from 'gsap';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import router from '../router';
const wrapper = ref(null)
const logo = ref(null)
const credits = ref(null)
const closeMenuBtn = ref(null)

let tl = gsap.timeline({
    defaults: {
        ease: 'power4.inOut'
    },
    onStart: ()=>{
        wrapper.value.style.display = 'flex'
        closeMenuBtn.value.style.display = 'block'
    },
    
    onReverseComplete: ()=>{
        wrapper.value.style.display = 'none'
        closeMenuBtn.value.style.display = 'none'
    },
})
tl.pause()

const closeMenu = ()=>{
    tl.reverse()
}

const openMenu = ()=>{
    tl.play()
}

const goToCategory = (name)=>{
    router.push(name)
    tl.reverse()
}

onMounted(()=>{
    const chars = document.querySelectorAll('.char')
    tl.fromTo(wrapper.value, {x: window.innerWidth}, {x: 0, duration: 2})
    tl.fromTo(logo.value, {opacity: 0}, {opacity: 1})
    for (let i = 0; i < chars.length; i++) {
        const c = chars[i];
        tl.fromTo(c, {y: 300}, {y: 0, ease: 'expo.inOut', duration: 2}, i/50)
    }
    tl.fromTo(credits.value, {opacity: 0}, {opacity: 1})

    document.getElementById('open-menu').addEventListener('click', ()=>{
        
    })

    document.getElementById('close-menu').addEventListener('click', ()=>{

    })
})
</script>