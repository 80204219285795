<script setup>
import { onBeforeMount, onMounted, onUpdated, ref } from 'vue';
import Sidebar from './components/Sidebar.vue';
import Menu from './components/Menu.vue';
import gsap from 'gsap';
const sidebar = ref(null)  
const pointer = ref(null)
import Loader from './components/Loader.vue';

onMounted(()=>{
  
})

</script>

<template> 
  <!-- <Loader></Loader> -->
  <Menu></Menu>
  <Sidebar ref="sidebar"></Sidebar>
  <router-view :key="$route.fullPath"></router-view>
</template>

<style scoped>  
</style>
