<template>
    <div @pointerenter="onEnter" @pointerleave="onLeave" ref="wrapper" class="wrapper">

        <video v-if="preview" style="display: none;" preload="none" muted playsinline loop class="content z-10" :src="preview"></video>
        <img loading="lazy" v-if="image" class="content z-0" :src="image">
        
        <div class="hidden lg:block absolute z-20  w-[70%] text-center -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 text-white opacity-0 leading-none">
            <div v-if="title" class="text-xl font-medium uppercase font-['PP_Monument_Extended']">{{title}}</div>
            <div v-if="client" class="uppercase font-['Roboto_Mono']">{{client}}</div>
        </div>
        
        <div class="block lg:hidden absolute z-20 w-[70%] text-left left-5 bottom-5 text-white">
            <div v-if="title" class="text-xl font-medium uppercase font-['PP_Monument_Extended']">{{title}}</div>
            <div v-if="client" class="uppercase font-['Roboto_Mono']">{{client}}</div>
        </div>

        <div v-if="isLoading == false" class="absolute w-full h-full flex items-center justify-center z-[100] bg-black font-['Roboto_Mono'] text-xs uppercase text-white ">
            <div>[ loading<span>.</span><span>.</span><span>.</span> ]</div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import gsap from 'gsap'

const isHovering = ref(false)
const wrapper = ref(null)
const pointer = ref(null)

const isLoading = ref(false)

const props = defineProps({
    preview: String,
    image: String,
    client: String,
    title: String,
})

const hovering_forward = (wrapper)=>{
    const video = wrapper.children[0]
    const img = wrapper.children[1]
    const txt = wrapper.children[2]
    let tl = gsap.timeline({
        onStart:()=>{
            video.style.display = 'block'
        }
    })
    tl.fromTo(video, {opacity: 0}, {opacity: 1}, 'default')
    tl.fromTo(txt, {opacity: 0}, {opacity: 1}, 'default')
}

const hovering_reverse = (wrapper)=>{
    const video = wrapper.children[0]
    const img = wrapper.children[1]
    const txt = wrapper.children[2]
    let tl = gsap.timeline({
        onComplete:()=>{
            video.style.display = 'none'
        }
    })
    tl.to(video, {opacity: 0}, 'default')
    tl.to(txt, {opacity: 0}, 'default')
}

const onEnter = (e)=>{ 
    let video = e.target.children[0]
    hovering_forward(e.target)

    var isPlaying = video.currentTime > 0 && !video.paused && !video.ended 
    && video.readyState > video.HAVE_CURRENT_DATA;

    if (!isPlaying) {
    video.play();
    }
 }

const onLeave = (e)=>{
    let video = e.target.children[0]
    hovering_reverse(e.target)
    video.pause()  
}

onMounted(()=>{
   const thumbnailImage = new Image()
   thumbnailImage.src = props.image
   thumbnailImage.onload = ()=>{
    isLoading.value = true
   }
})
</script>

<style scoped>
.wrapper { 
    @apply w-full h-full relative  duration-300 ease-in-out border-black cursor-pointer 
}
.content { 
    @apply absolute w-full h-full top-0 left-0 object-cover 
}
</style>