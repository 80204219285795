import gsap from "gsap";

function animateGrid(target){
    if (target) {
        let tl = gsap.timeline({})
        for (let i = 0; i < target.children.length; i++) {
            const el = target.children[i];
            tl.fromTo(el, {opacity: 0, y: 600, scaleY: 1.2}, {opacity: 1, y: 0, scaleY: 1, duration: 0.95, ease:'expo.out'}, i/10)
        }
    }
}

function animateSidebar(target){
    if (target) {
        let tl = gsap.timeline({})
        for (let i = 0; i < target.children.length; i++) {
            const el = target.children[i];
            tl.fromTo(el, {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: .95, ease: 'expo.out'}, i/2)
        }
    }
}

function initGrid(target){ 
    if(target){
        for (let i = 0; i < target.children.length; i++) { 
            const el = target.children[i];
            gsap.set(el, {opacity: 0, y: 600, scaleY: 1.2})
        }
    }
}

function initSidebar(target){ 
    if (target) {
        for (let i = 0; i < target.children.length; i++) { 
            const el = target.children[i];
            gsap.set(el, {opacity: 0, y: 100})
        }
    }
        
    }

export {
    animateGrid,
    animateSidebar,
    initGrid,
    initSidebar,
}