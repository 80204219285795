import { createApp } from 'vue'
import './style.css'
import './assets/helvetica/stylesheet.css'
import './assets/PPMonumentBlack/stylesheet.css'
import App from './App.vue'
import router from './router'

// index.js
import '@material/web/button/filled-button.js';
import '@material/web/button/outlined-button.js';
import '@material/web/checkbox/checkbox.js';
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

//Default theme
localStorage.setItem('--theme', 'light')

createApp(App).use(router).mount('#app')
