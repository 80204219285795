import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue' 
import Category from '../views/Category.vue' 
import Project from '../views/Project.vue' 
import Bio from '../views/Bio.vue' 

const routes = [
    {path: '/', component: Home, name: 'Home'},
    {path: '/work/:slug', component: Project, name: 'Project'}, 
    {path: '/:slug', component: Category, name: 'Category'}, 
    {path: '/bio', component: Bio, name: 'Bio'}, 
 ]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
  }) 

export default router