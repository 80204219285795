<template>
    <div class="main-content">
        <div class="font-['Roboto_Mono'] pl-3 pr-3 pt-1 pb-1 z-[100] rotate-90 fixed -right-28 top-1/2 -translate-y-1/2  text-xs cursor-pointer uppercase bg-[#54ff20]">
            <a target="_blank" rel="noopener noreferrer" href="https://lapetitetribu.co/">made with &#9829; by [ la petite tribu ]</a>
        </div>
        <div id="bio" class="bio-wrapper main-content flex flex-col font-['Roboto_Mono'] overflow-y-auto">
    
            <div class="lg:m-0 mt-16  p-4 uppercase font-bold text-2xl">
                <div>
                    Basé à Dakar, je suis un réalisateur et artiste CGI fusionnant modernité et tradition pour créer des expériences vidéo uniques. 
                </div>
                <div></div>
            </div>
    
            <div class="p-4 mb-10">
                <div><img src="" alt=""></div>
                <div>
                    Fort d'une expérience variée, de la réalisation de films au montage, je mets l'accent sur l'émotion et l'aspect humain. 
                    Chaque projet est minutieusement planifié avec une direction artistique intégrée, garantissant une expérience cinématographique immersive.
                </div>
            </div>
            
                <div v-for="l in links" class="pt-6 pb-6 pl-6 border-t border-black text-xs cursor-pointer uppercase hover:bg-[#54ff20]">
                   <a target="_blank" rel="noopener noreferrer" :href="l.url">{{ l.name }}</a>
                 </div>
                 
        </div>
    </div>
</template>
 
<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import {createClient} from '@sanity/client'
import gsap from 'gsap';
const links = ref(null)

const client = createClient({
  projectId: 'ruqolkxa',
  dataset: 'production',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
})

onBeforeMount(async()=>{
    await client.fetch(`*[_type == "misc"]`).then((response)=>{links.value = response})
})

onMounted(()=>{
    gsap.fromTo('.main-content', {opacity: 0}, {opacity: 1})
})
</script>

<style scoped>
.bio-wrapper {
    @apply dark:text-white dark:bg-[#121212] lg:p-20 p-5
    text-black bg-white lg:w-[75vw] lg:ml-[25vw] lg:h-screen lg:fixed 
}
 
</style>