<script setup>
import { ref, onMounted } from 'vue' 
import { useRoute, useRouter } from 'vue-router';
import { animateSidebar } from '../js/animations';
import gsap from 'gsap';

const router = useRouter()
const route = useRoute()
const sidebarItems = ref(null)
const dark = ref(null)

const goHome = ()=>{  
  if (router.currentRoute.value.fullPath !== '/') {
    const mainContent = document.querySelector('.main-content')
    gsap.to(mainContent, {opacity: 0, onComplete:()=>router.push('/')})
  } else {
    return
  }
}

const goToCategory = (name)=>{
  router.push(name) 
    // tl.reverse()
}

onMounted(()=>{

  dark.value.addEventListener('click', ()=>{

    const sidebar = document.getElementById("sidebar")
    const project = document.getElementById("project")
    const bio = document.getElementById("bio")
  
    if (localStorage.getItem('--theme') == 'light') {
      localStorage.setItem('--theme', 'dark')

      if (sidebar) {
        sidebar.classList.add('dark')
      }

      if (project) {
        project.classList.add('dark')
      }

      if (bio) {
        bio.classList.add('dark')
      }

      document.body.classList.add('dark')
      
    } 
    
    else if (localStorage.getItem('--theme') == 'dark') {
      localStorage.setItem('--theme', 'light')
      
      if (sidebar) {
        sidebar.classList.remove('dark')
      }
      
      if (project) {
        project.classList.remove('dark')
      }

      if (bio) {
        bio.classList.remove('dark')
      }
      
      document.body.classList.remove('dark')
    } 

  })

  animateSidebar(sidebarItems.value)
  
})
</script>

<template>

  <div class="lg:block md:block hidden duration-1000 font-['Roboto_Mono']"  id="sidebar">

    <div id="sidebar-items" ref="sidebarItems"
    class="--sidebar-width fixed flex flex-col justify-between items-center text-center 
    h-screen pt-10 pb-10 dark:bg-[#121212] z-50 dark:text-white uppercase">
      
      <div class="flex flex-col justify-center items-center w-full cursor-pointer" @click="goHome">
        <div class="w-[45%] relative dark:fill-white">
          <svg x="0px" y="0px"
            viewBox="0 0 300 140" xml:space="preserve">
          <g>
            <path d="M28.7,106.3c1,1.8,2.4,3.3,4,4.4c1.6,1.1,3.5,2,5.6,2.5c2.1,0.5,4.3,0.8,6.6,0.8c1.6,0,3.4-0.2,5.2-0.6
              c1.8-0.4,3.4-1,4.9-1.8c1.5-0.8,2.7-1.9,3.7-3.3c1-1.4,1.5-3.1,1.5-5.2c0-3.5-2.3-6.1-7-7.9c-4.6-1.7-11.1-3.5-19.4-5.2
              c-3.4-0.8-6.7-1.7-9.9-2.7c-3.2-1-6.1-2.4-8.6-4.1c-2.5-1.7-4.5-3.8-6.1-6.4C7.8,74.5,7,71.3,7,67.6c0-5.5,1.1-9.9,3.2-13.4
              c2.1-3.5,4.9-6.2,8.4-8.3c3.5-2,7.4-3.4,11.8-4.3c4.4-0.8,8.8-1.2,13.4-1.2s9,0.4,13.3,1.3c4.3,0.9,8.2,2.3,11.5,4.4
              c3.4,2.1,6.2,4.8,8.4,8.3c2.2,3.4,3.6,7.8,4,13H59.1c-0.3-4.5-2-7.5-5.1-9.1C51,56.8,47.4,56,43.2,56c-1.3,0-2.7,0.1-4.3,0.2
              c-1.5,0.2-2.9,0.5-4.2,1.1c-1.3,0.5-2.3,1.3-3.2,2.4c-0.9,1-1.3,2.4-1.3,4.2c0,2.1,0.8,3.8,2.3,5.1c1.5,1.3,3.5,2.4,6,3.2
              c2.5,0.8,5.3,1.6,8.4,2.2c3.2,0.7,6.4,1.4,9.7,2.1c3.4,0.8,6.7,1.7,9.9,2.8c3.2,1.1,6.1,2.5,8.6,4.3c2.5,1.8,4.5,4,6.1,6.7
              c1.5,2.7,2.3,6,2.3,9.9c0,5.6-1.1,10.2-3.4,14c-2.2,3.8-5.2,6.8-8.8,9.1c-3.6,2.3-7.7,3.9-12.4,4.8c-4.6,0.9-9.4,1.4-14.2,1.4
              c-4.9,0-9.7-0.5-14.4-1.5c-4.7-1-8.9-2.6-12.5-4.9c-3.7-2.3-6.7-5.3-9-9.1c-2.3-3.8-3.6-8.5-3.8-14.2h22.1
              C27.2,102.4,27.7,104.5,28.7,106.3z"/>
            <path d="M115.7,107.1c3.5,3.4,8.5,5.1,15.1,5.1c4.7,0,8.7-1.2,12.1-3.5c3.4-2.3,5.5-4.8,6.2-7.4h20.5c-3.3,10.1-8.3,17.4-15.1,21.8
              c-6.8,4.4-15,6.5-24.6,6.5c-6.7,0-12.7-1.1-18-3.2c-5.3-2.1-9.9-5.2-13.6-9.1c-3.7-3.9-6.6-8.6-8.6-14.1c-2-5.5-3-11.5-3-18
              c0-6.3,1-12.2,3.1-17.7c2.1-5.5,5-10.2,8.8-14.2c3.8-4,8.4-7.1,13.7-9.4c5.3-2.3,11.2-3.4,17.6-3.4c7.2,0,13.5,1.4,18.8,4.2
              c5.3,2.8,9.7,6.5,13.2,11.2c3.4,4.7,5.9,10,7.4,16c1.5,6,2.1,12.3,1.6,18.8H110C110.3,98.2,112.2,103.7,115.7,107.1z M142,62.6
              c-2.8-3.1-7-4.6-12.7-4.6c-3.7,0-6.8,0.6-9.2,1.9c-2.5,1.3-4.4,2.8-5.9,4.7c-1.5,1.9-2.5,3.8-3.1,5.9c-0.6,2.1-1,3.9-1.1,5.6h37.8
              C146.7,70.1,144.8,65.6,142,62.6z"/>
            <path d="M202.5,10.5v116.9h-23.2V10.5H202.5z"/>
            <path d="M239.5,107.1c3.5,3.4,8.5,5.1,15.1,5.1c4.7,0,8.7-1.2,12.1-3.5c3.4-2.3,5.5-4.8,6.2-7.4h20.5c-3.3,10.1-8.3,17.4-15.1,21.8
              c-6.8,4.4-15,6.5-24.6,6.5c-6.7,0-12.7-1.1-18-3.2c-5.3-2.1-9.9-5.2-13.6-9.1c-3.7-3.9-6.6-8.6-8.6-14.1c-2-5.5-3-11.5-3-18
              c0-6.3,1-12.2,3.1-17.7c2.1-5.5,5-10.2,8.8-14.2c3.8-4,8.4-7.1,13.7-9.4c5.3-2.3,11.2-3.4,17.6-3.4c7.2,0,13.5,1.4,18.8,4.2
              c5.3,2.8,9.7,6.5,13.2,11.2c3.4,4.7,5.9,10,7.4,16c1.5,6,2.1,12.3,1.6,18.8h-61.1C234.1,98.2,236,103.7,239.5,107.1z M265.7,62.6
              c-2.8-3.1-7-4.6-12.7-4.6c-3.7,0-6.8,0.6-9.2,1.9c-2.5,1.3-4.4,2.8-5.9,4.7c-1.5,1.9-2.5,3.8-3.1,5.9c-0.6,2.1-1,3.9-1.1,5.6h37.8
              C270.5,70.1,268.5,65.6,265.7,62.6z"/>
          </g>
          </svg>
        </div>
        <div class="uppercase text-xs duration-200">
          [ director / filmmaker ]
        </div>
      </div>

      <div class="text-xs leading-6 flex gap-1 flex-col">
        <div class="hover:bg-[#54ff20] pr-3 pl-3 cursor-pointer" @click="goToCategory('/bio')" style="clip-path: inset(-40px 0);">bio</div>
        <div class="hover:bg-[#54ff20] pr-3 pl-3 cursor-pointer" @click="goHome()" style="clip-path: inset(-40px 0);">tout les projets</div>
        <div class="hover:bg-[#54ff20] pr-3 pl-3 cursor-pointer" @click="goToCategory('/clips')" style="clip-path: inset(-40px 0);">clips</div>
        <div class="hover:bg-[#54ff20] pr-3 pl-3 cursor-pointer" @click="goToCategory('/commercial')" style="clip-path: inset(-40px 0);">commercial</div>
        <div class="hover:bg-[#54ff20] pr-3 pl-3 cursor-pointer" @click="goToCategory('/cinema')" style="clip-path: inset(-40px 0);">cinéma</div>
        </div>

      <div class="text-xs leading-6 flex gap-1 flex-col"> 
        <a href='https://www.instagram.com/seleman_dieye/' target="_blank" rel="noopener noreferrer" class="hover:bg-[#54ff20] pr-3 pl-3 cursor-pointer">+ instagram +</a>
        <a href='https://www.linkedin.com/in/mame-s%C3%A9l%C3%A9mane-dieye-b99885a8/' target="_blank" rel="noopener noreferrer" class="hover:bg-[#54ff20] pr-3 pl-3 cursor-pointer">+ linkedin +</a>
      </div>
      
      <div class="text-xs leading-6">
        <div>[+221] 77 409 45 59</div>
        <a href="mailto:selevfx@gmail.com" class="mb-3">[ email ]</a>
        
        <div ref="dark" class="relative w-10 h-10 mx-auto hidden" style="clip-path: inset(0 0);">
          <span class="material-symbols-outlined absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            dark_mode
          </span>
          <span class="material-symbols-outlined absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden">
            light_mode
          </span>
        </div>
       </div>

    </div>

  </div>

</template>

<style scoped> 
</style>
