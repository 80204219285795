<template>
    <div v-for="p in projects" 
    class="w-full border-t border-black pt-5 pb-5 pr-2 pl-8 flex gap-8 cursor-pointer hover:bg-[#54ff20]"
    @click="router.push(p.slug.current)"
    >
        <div class="w-[33.33%]">{{ p.name }}</div>
        <div class="w-[33.33%] font-semibold">{{ p.client }}</div>
    </div>
</template>
 

<script setup>
import { onMounted, onUpdated, defineProps, ref } from 'vue'
import {createClient} from '@sanity/client'
import { useRoute, useRouter } from 'vue-router';
const client = createClient({
  projectId: 'ruqolkxa',
  dataset: 'production',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
})
const route = useRoute()
const router = useRouter()
const props = defineProps({
    category: String
})

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

// && category.name == "` + props.category + `"

const projects = ref(null)

onMounted(async ()=>{
    await client.fetch(`*[_type == "project" && category->slug.current == "` + props.category + `"]{
        name,
        slug,
        client,
        "preview": preview.asset->url,
        category->, 
        "image": image.asset->url,
        videoId
    }`).then((response)=>{
        projects.value = shuffle(response)
    })
})

onUpdated(()=>{ 
    // let tl = gsap.timeline({})
}) 
</script>

