<template> 

    <div class="absolute z-50 bg-white mix-blend-difference 
  font-['Roboto_Mono'] hidden md:hidden lg:block opacity-0 text-xs p-2 pointer-events-none" ref="pointer">[ PLAY ]</div>

    <div class="flex lg:hidden md:hidden flex-col justify-center items-center 
    w-full cursor-pointer pt-16 pb-16 font-['Roboto_Mono']">
        <div ref="logoMobile" class="w-[85%] relative dark:fill-white">
            <svg x="0px" y="0px" viewBox="0 0 300 140" xml:space="preserve">
                <g>
                    <path d="M28.7,106.3c1,1.8,2.4,3.3,4,4.4c1.6,1.1,3.5,2,5.6,2.5c2.1,0.5,4.3,0.8,6.6,0.8c1.6,0,3.4-0.2,5.2-0.6
                    c1.8-0.4,3.4-1,4.9-1.8c1.5-0.8,2.7-1.9,3.7-3.3c1-1.4,1.5-3.1,1.5-5.2c0-3.5-2.3-6.1-7-7.9c-4.6-1.7-11.1-3.5-19.4-5.2
                    c-3.4-0.8-6.7-1.7-9.9-2.7c-3.2-1-6.1-2.4-8.6-4.1c-2.5-1.7-4.5-3.8-6.1-6.4C7.8,74.5,7,71.3,7,67.6c0-5.5,1.1-9.9,3.2-13.4
                    c2.1-3.5,4.9-6.2,8.4-8.3c3.5-2,7.4-3.4,11.8-4.3c4.4-0.8,8.8-1.2,13.4-1.2s9,0.4,13.3,1.3c4.3,0.9,8.2,2.3,11.5,4.4
                    c3.4,2.1,6.2,4.8,8.4,8.3c2.2,3.4,3.6,7.8,4,13H59.1c-0.3-4.5-2-7.5-5.1-9.1C51,56.8,47.4,56,43.2,56c-1.3,0-2.7,0.1-4.3,0.2
                    c-1.5,0.2-2.9,0.5-4.2,1.1c-1.3,0.5-2.3,1.3-3.2,2.4c-0.9,1-1.3,2.4-1.3,4.2c0,2.1,0.8,3.8,2.3,5.1c1.5,1.3,3.5,2.4,6,3.2
                    c2.5,0.8,5.3,1.6,8.4,2.2c3.2,0.7,6.4,1.4,9.7,2.1c3.4,0.8,6.7,1.7,9.9,2.8c3.2,1.1,6.1,2.5,8.6,4.3c2.5,1.8,4.5,4,6.1,6.7
                    c1.5,2.7,2.3,6,2.3,9.9c0,5.6-1.1,10.2-3.4,14c-2.2,3.8-5.2,6.8-8.8,9.1c-3.6,2.3-7.7,3.9-12.4,4.8c-4.6,0.9-9.4,1.4-14.2,1.4
                    c-4.9,0-9.7-0.5-14.4-1.5c-4.7-1-8.9-2.6-12.5-4.9c-3.7-2.3-6.7-5.3-9-9.1c-2.3-3.8-3.6-8.5-3.8-14.2h22.1
                    C27.2,102.4,27.7,104.5,28.7,106.3z"/>
                    <path d="M115.7,107.1c3.5,3.4,8.5,5.1,15.1,5.1c4.7,0,8.7-1.2,12.1-3.5c3.4-2.3,5.5-4.8,6.2-7.4h20.5c-3.3,10.1-8.3,17.4-15.1,21.8
                    c-6.8,4.4-15,6.5-24.6,6.5c-6.7,0-12.7-1.1-18-3.2c-5.3-2.1-9.9-5.2-13.6-9.1c-3.7-3.9-6.6-8.6-8.6-14.1c-2-5.5-3-11.5-3-18
                    c0-6.3,1-12.2,3.1-17.7c2.1-5.5,5-10.2,8.8-14.2c3.8-4,8.4-7.1,13.7-9.4c5.3-2.3,11.2-3.4,17.6-3.4c7.2,0,13.5,1.4,18.8,4.2
                    c5.3,2.8,9.7,6.5,13.2,11.2c3.4,4.7,5.9,10,7.4,16c1.5,6,2.1,12.3,1.6,18.8H110C110.3,98.2,112.2,103.7,115.7,107.1z M142,62.6
                    c-2.8-3.1-7-4.6-12.7-4.6c-3.7,0-6.8,0.6-9.2,1.9c-2.5,1.3-4.4,2.8-5.9,4.7c-1.5,1.9-2.5,3.8-3.1,5.9c-0.6,2.1-1,3.9-1.1,5.6h37.8
                    C146.7,70.1,144.8,65.6,142,62.6z"/>
                    <path d="M202.5,10.5v116.9h-23.2V10.5H202.5z"/>
                    <path d="M239.5,107.1c3.5,3.4,8.5,5.1,15.1,5.1c4.7,0,8.7-1.2,12.1-3.5c3.4-2.3,5.5-4.8,6.2-7.4h20.5c-3.3,10.1-8.3,17.4-15.1,21.8
                    c-6.8,4.4-15,6.5-24.6,6.5c-6.7,0-12.7-1.1-18-3.2c-5.3-2.1-9.9-5.2-13.6-9.1c-3.7-3.9-6.6-8.6-8.6-14.1c-2-5.5-3-11.5-3-18
                    c0-6.3,1-12.2,3.1-17.7c2.1-5.5,5-10.2,8.8-14.2c3.8-4,8.4-7.1,13.7-9.4c5.3-2.3,11.2-3.4,17.6-3.4c7.2,0,13.5,1.4,18.8,4.2
                    c5.3,2.8,9.7,6.5,13.2,11.2c3.4,4.7,5.9,10,7.4,16c1.5,6,2.1,12.3,1.6,18.8h-61.1C234.1,98.2,236,103.7,239.5,107.1z M265.7,62.6
                    c-2.8-3.1-7-4.6-12.7-4.6c-3.7,0-6.8,0.6-9.2,1.9c-2.5,1.3-4.4,2.8-5.9,4.7c-1.5,1.9-2.5,3.8-3.1,5.9c-0.6,2.1-1,3.9-1.1,5.6h37.8
                    C270.5,70.1,268.5,65.6,265.7,62.6z"/>
                </g>
            </svg>
        </div>

        <div class="uppercase text-xs duration-200">
        [ director / filmmaker ]
        </div> 
    </div>

    <div ref="grid" class="-grid main-content" id="grid-items"> 
         <div @click="go_to('/work/' + d.slug.current)" class="-grid-item" v-for="d in projects"> 
            <Thumbnail :client="d.client" :title="d.name" :image="d.image" :preview="d.preview"></Thumbnail>
        </div> 
    </div>

</template>

<script setup>
import { onMounted, onUpdated, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Lenis from '@studio-freight/lenis'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Thumbnail from '../components/Thumbnail.vue'
import {createClient} from '@sanity/client'
import Preload from 'preload-it';
import {animateGrid, animateSidebar} from '../js/animations'

const client = createClient({
  projectId: 'ruqolkxa',
  dataset: 'production',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
})

gsap.registerPlugin(ScrollTrigger)
const grid = ref(null)
const logoMobile = ref(null)
const router = useRouter()
const route = useRoute()
const pointer = ref(null)

const go_to = (path)=>{
    gsap.to(grid.value,{
        opacity: 0,
        onComplete: ()=>{
            router.push(path)
        }
    })
}

const projects = ref(null)

onMounted(async ()=>{ 
 
    await client.fetch(`*[_type == "project"]{
        name,
        slug,
        client,
        category->,
        "preview": preview.asset->url,
        "image": image.asset->url
    }`)

    .then((response)=>{   
        projects.value = response  
    })  

    //smooth scroll
    const lenis = new Lenis() 
    lenis.on('scroll', ScrollTrigger.update) 
    gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
    }) 

    gsap.fromTo(logoMobile.value, {width: '85%'}, {width: '45%', scrollTrigger: {
        trigger: logoMobile.value,
        scrub: true
    }})

    gsap.ticker.lagSmoothing(0)
    //

    grid.value.addEventListener('mousemove', (e)=>{ 
    if (pointer.value) { 
      gsap.set(pointer.value, {opacity: 1})
      gsap.to(pointer.value, {x: e.pageX, y: e.pageY, duration: 0.5})
    }})

    grid.value.addEventListener('mouseleave', (e)=>{ 
    if (pointer.value) { 
      gsap.set(pointer.value, {opacity: 0})
    }})

})

 

onUpdated(()=>{  
     

    let children = []

    for (let i = 0; i < grid.value.children.length; i++) {
        const element = grid.value.children[i];
        children.push(element)  
    }
 
    const chunkSize = 5;
    const chunks = []
    
    for (let i = 0; i < children.length; i += chunkSize) {

        const chunk = children.slice(i, i + chunkSize);

        if (chunk.length == chunkSize) {
            chunk[0].classList.add('lg:col-span-3')
            chunk[1].classList.add('lg:col-span-2')
            chunk[2].classList.add('lg:col-span-2')
            chunk[3].classList.add('lg:col-span-3')
            chunk[4].classList.add('lg:col-span-5')
        } 

        else if (chunk.length == 4) {
            chunk[0].classList.add('lg:col-span-3')
            chunk[1].classList.add('lg:col-span-2')
            chunk[2].classList.add('lg:col-span-2')
            chunk[3].classList.add('lg:col-span-3') 
        }

        else if (chunk.length == 3) {
            chunk[0].classList.add('lg:col-span-2')
            chunk[1].classList.add('lg:col-span-3')
            chunk[2].classList.add('lg:col-span-' + chunkSize) 
        }

        else if (chunk.length == 2) {
            chunk[0].classList.add('lg:col-span-3')
            chunk[1].classList.add('lg:col-span-2') 
        }

        else if (chunk.length == 1) {
            for (let i = 0; i < chunk.length; i++) {
                const c = chunk[i];
                c.classList.add('lg:col-span-' + chunkSize) 
            }
        }

        // do whatever
        chunks.push(chunk)
    }
 
    // reset grid
    animateGrid(grid.value)


})

</script>

<!-- <script setup>
import { onBeforeMount, onMounted, onUpdated, ref } from 'vue';
import Thumbnail from '../components/Thumbnail.vue'
import Lenis from '@studio-freight/lenis'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const data = ref([])
const grid = ref(null)
const links = ref([])

 const setLayout = (seed, grid)=>{
    if (seed == 'layout-a') {
        grid.children[0].classList.add('lg:col-span-2') 
        grid.children[1].classList.add('lg:col-span-2') 
        grid.children[2].classList.add('lg:col-span-1') 
        grid.children[3].classList.add('lg:col-span-3') 
    } else if (seed == 'layout-b') {
        grid.children[0].classList.add('lg:col-span-2') 
        grid.children[1].classList.add('lg:col-span-2') 
        grid.children[2].classList.add('lg:col-span-2') 
        grid.children[3].classList.add('lg:col-span-2') 
    } else if (seed == 'layout-c') {
        grid.children[0].classList.add('lg:col-span-4') 
        grid.children[1].classList.add('lg:col-span-2') 
        grid.children[2].classList.add('lg:col-span-2') 
        grid.children[3].classList.add('lg:col-span-4') 
    } else if (seed == 'layout-d') {
        grid.children[0].classList.add('lg:col-span-1') 
        grid.children[1].classList.add('lg:col-span-2') 
        grid.children[2].classList.add('lg:col-span-1') 
        grid.children[3].classList.add('lg:col-span-4') 
    }
}
  
function grouped(arr, size) {
    let newArr = [];
    for (let i = 0; i < arr.length; i += size) {
        newArr.push(arr.slice(i, i + size));
    }
    return newArr;
}

function getRandomItems(arr, num) {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
}

onBeforeMount(()=>{
    for (let i = 0; i < 25; i++) {
        data.value.push(i)
    }
})

onMounted(()=>{  

    //smooth scroll
    const lenis = new Lenis()

    lenis.on('scroll', (e) => {
    //console.log(e)
    })

    lenis.on('scroll', ScrollTrigger.update)

    gsap.ticker.add((time)=>{
    lenis.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)
    //

    console.log(grid.value.children.length%2)

    //Stylize grid
    for (let i = 0; i < grid.value.children.length; i++) {
        const element = grid.value.children[i]; 
        //ScrollTrigger
        gsap.fromTo(element, {y: 600}, {
            scrollTrigger: {
                trigger: element,
                scrub: true,
                // markers: true,
                start: 'top 100%'
            }, // start the animation when ".box" enters the viewport (once)
            y: 0
        });
    }
})

onUpdated(()=>{ 
})
</script> -->

<style scoped>
.-grid { 
    @apply grid grid-cols-1 lg:grid-cols-5 md:w-[75vw] md:ml-[25vw] lg:w-[75vw] lg:ml-[25vw] gap-3 p-6 lg:p-0
}
.-grid-item { 
    @apply  border-red-500 h-[300px]
}
 
</style>